import * as React from 'react';
import ModalDialog from './ModalDialog';

import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import moment from 'moment';

import globals from '../globals';

import s from '../settings';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const currentLanguage = localStorage.getItem('language');

export default function CustomizedDialogs(props) {

  const [values, setValues] = React.useState({});

  const onChange = (event, a) => {
    values[a.cname] = event.target.value;
    setValues({...values});
  }

  const onSave = () => {
    props.onAttributeValuesSaved(values, props.attr.tname, props.attr.gid);
  }

  const components = [];

  components.push(<div style={{display: 'flex', gap:'30px'}}>
    {
      globals.groups.map(g => {
        const value = (values[g.key] !== undefined ? values[g.key] : props.attr.featureProperties[g.key]) ? true : false;
        return <div style={{display: 'flex'}}>
          <img src={g.icons.modro} width={21}/>
          <Checkbox key={g.key}
            checked={value}
            onChange={(event) => onChange({
              target:{
                value: event.target.checked ? 1 : 0
              }
            }, {
              cname: g.key
            })}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
      })
    }
  </div>)

  const includedPhotos = {};

  const photoAttributesByKey = {};

  for (const a of props.attr.attDefs) {
    if (a.is_photo) {
      photoAttributesByKey[a.cname] = a;
    } 
  }

  for (const a of props.attr.attDefs) {
    if (a.is_photo) continue;

    const value = values[a.cname] || props.attr.featureProperties[a.cname];

    if (a.code_list) {
      components.push(
      <TextField
          sx={{width:'30vw'}}
          select
          InputLabelProps={{
            shrink: true,
          }}
          label={a.label + ` (${a.cname})`}
          value={value}
          size="small"
          onChange={(event) => onChange(event, a)}
      >
        {
          Object.keys(a.code_list).map(key => <MenuItem value={key}>{a.code_list[key][currentLanguage] || a.code_list[key]['en']}</MenuItem>)
        }
      </TextField>);
    }
    else if (a.data_type==='date') {
      components.push(<LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker 
          value={value  && moment(value)}
          label={a.label + ` (${a.cname})`}
          slotProps={{ textField: { 
            size: 'small',
            InputLabelProps: { shrink: true } } }}
        />
      </LocalizationProvider>);
    }
    else {
      if (a.data_type === 'string' && a.cname.includes('_note')) {
        const key = a.cname.replace('_note','').replace('_en','');
        if (photoAttributesByKey[key] && !includedPhotos[key]) {
          let src = props.attr.featureProperties[key];
          if (src) {
            src = src.trim().toLowerCase();
            if (src) {
              components.push(<div><div style={{fontWeight:'bold',marginBottom:'3px'}}>({a.cname})</div><img style={{width:'100%'}} src={s.fotoRoot + src}/></div>);
              includedPhotos[key] = true;
            }
          }
        }
      }

      const type=a.data_type==='integer' ? 'number' : 'text';

      components.push(<TextField onChange={(event) => onChange(event, a)} type={type} InputLabelProps={{
        shrink: true,
      }} size="small" id="outlined-basic" label={a.label + ` (${a.cname})`} value={value} variant="outlined" />);
    }
  }
  return (
      <ModalDialog onSave={onSave} onClose={props.onClose} title="Attribute editor">
        <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          {components.map((element) => (
            // Use the React.cloneElement to add the key prop to each element
            React.cloneElement(element, { key: element.key })
          ))}
        </div>
      </ModalDialog>
  );
}
