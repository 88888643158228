import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import facebookIcon from '../assets/02_ikone/Facebook2-01.svg';
import contactIcon from '../assets/02_ikone/contact.svg';
import instagramIcon from '../assets/02_ikone/Instagram.svg';
import linkedInIcon from '../assets/02_ikone/Linked_In.svg';
import webPageIcon from '../assets/02_ikone/Web_page.svg';
import helpIcon from '../assets/02_ikone/Help.svg';

import campaignIcon from '../assets/02_ikone/campaign.svg';

import ModalDialog from './ModalDialog'

import utils from '../utils';

import globals from '../globals';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import infoIcon from '../assets/02_ikone/Info2-01.svg';
import shareIconSvg from '../assets/02_ikone/Ikone_novo/Share.svg';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

import MenuIcon from '@mui/icons-material/Menu';
import LanguageSelector from './LanguageSelector';

import axios from 'axios'; 
import s from '../settings';

axios.defaults.baseURL = s[s.config].apiUrl

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...({
      fontFamily: 'Raleway, sans-serif',
      fontSize: '18pt',
      fontStyle: 'italic',
      color: 'rgb(21,77,127)',
      backgroundColor: sessionStorage.getItem('editor') ? 'red' : 'rgb(241,242,242)',
      marginLeft: 0,
      width: '100%' //`calc(100% - ${drawerWidth}px)`,
    }),
  }));

  const onLanguageChange = (event) => {
    console.log('onLanguageChange', onLanguageChange)
    const currentLanguage = localStorage.getItem('language') || 'en';
    const languageSet = event.target.value;
    if (languageSet !== currentLanguage) {
      localStorage.setItem('language', languageSet);
      window.location.reload();
    }
  };


export default (props) => 
{
  const [isEditor, setIsEditor] = React.useState(false);
  const [mobileIconsDialogOpened, setMobileIconsDialogOpened] = React.useState(false);

  const CampaingIcons = ()=><span>
    <IconButton onClick={()=>{}}>  
    <img style={{height:'32px'}} src={helpIcon} />
    </IconButton>
    <IconButton onClick={()=>props.onOpenAboutIconClicked && props.onOpenAboutIconClicked()}>  
    <img style={{height:'32px'}} src={infoIcon} />
    </IconButton>
    <IconButton onClick={()=>window.open("https://www.facebook.com/CESpaces4All", "_blank")}>  
    <img style={{height:'32px'}} src={facebookIcon} />
    </IconButton>
    <IconButton onClick={()=>window.open("https://www.instagram.com/ce_spaces4all/", "_blank")}>  
    <img style={{height:'32px'}} src={instagramIcon} />
    </IconButton>
    <IconButton onClick={()=>window.open("https://www.linkedin.com/company/ce-spaces4all/", "_blank")}>  
    <img style={{height:'32px'}} src={linkedInIcon} />
    </IconButton>
    <IconButton onClick={()=>window.open("https://www.interreg-central.eu/projects/ce-spaces4all/", "_blank")}>  
      <img style={{height:'32px'}} src={webPageIcon} />
    </IconButton>
    <IconButton onClick={()=>props.onContactIconClicked && props.onContactIconClicked()}>  
      <img style={{height:'32px'}} src={contactIcon} />
    </IconButton>
  </span>;

  const checkForEditor = async () => {
    try {
      const key = utils.getQueryParameter('key', false);
      if (key) {
        const response = await axios.post('is-editor', { key }); 
        sessionStorage.setItem('editor', key);
        setIsEditor(true);
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    sessionStorage.removeItem('editor');
  };

  // useEffect to call fetchData when the component mounts
  React.useEffect(() => {
    checkForEditor();
  }, []); // Empty dependency array ensures it only runs once when the component mounts

    return (<AppBar className="app-bar" position="fixed" open={true}>
        <div id="header-container">
          <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={()=>{props.setMenuOpen(!props.isMenuOpen)}}
              edge="start"
            >
              <MenuIcon />
          </IconButton>
        {
          <div id="naslov" style={{cursor: 'pointer'}} onClick={()=> window.location = window.location.origin}>
            <div>
                {sessionStorage.getItem('editor') ? "Web Accessibility Viewer - Editor" : globals.uit._wav_viewer}
            </div>
          </div>
        }
        <div id="header-controls">
            <div style={{marginRight: '15px'}}>
                {props.AutoCompleteGrouped}
            </div>
            <div id="icon-buttons">
              <CampaingIcons></CampaingIcons>
            </div>
            <div id="language-selector">
              <LanguageSelector selectedLanguage={localStorage.getItem('language') || 'en'} onChange={onLanguageChange}></LanguageSelector>
            </div>
            <IconButton id="icons-mobile" onClick={()=>{setMobileIconsDialogOpened(true)}}>  
              <img style={{height:'32px'}} src={campaignIcon} />
            </IconButton>
        </div>
        {mobileIconsDialogOpened &&
          <ModalDialog onClose={()=>setMobileIconsDialogOpened(false)} disableEscapeKeyDown={false}>
            <CampaingIcons></CampaingIcons>
          </ModalDialog>
        }
        </div>
        {
            props.loading && <div style={{color:"rgb(21,77,127)"}}>
            <LinearProgress color="inherit"/>
            </div>
        }
    </AppBar>)
}