import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';

import SlaboIcon from '@mui/icons-material/RemoveCircleOutline';
import SrednjeIcon from '@mui/icons-material/WarningAmber';
import DobroIcon from '@mui/icons-material/Slideshow';

import kvadrat from '@mui/icons-material/CropSquare';
import trikotnik from '@mui/icons-material/ChangeHistory';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import ModalDialog from './ModalDialog';

import AccordionCmp from './AccordionCmp';


import utils from '../utils';

import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import globals from '../globals';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const MainFilter = styled('div')({
    padding:'5px',
    fontSize:'13px',
    color: 'rgb(21,77,127)'
  });

  const FilterIcons = styled('div')({
    padding:'10px',
    marginTop:'15px',
    display: 'flex',
    justifyContent: 'space-evenly'
  });

  const FilterIcon = styled('div')({
    cursor: 'pointer'
  });

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: 'rgb(21,77,127)',
      '&:hover': {
        backgroundColor: alpha('rgb(21,77,127)', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'rgb(21,77,127)',
    },
  }));
  //.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked 

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    fontFamily: 'Raleway, sans-serif',
    fontSize: '11pt'
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{display:'flex'}}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

export default (props) => {
  const [openAccAssesmentDlg, setOpenAccAssesmentDlg] = React.useState(false);

  const onAccAssessmentClicked = () => {
    setOpenAccAssesmentDlg(true);
  }
  //count all objects

  if (!(globals.spatialNamesCounts && globals.groups)) return null;
  
  const colors = {
    acc_unknown:'gray',
    acc_not: 'red',
    acc_partially:'orange',
    acc_fully:'green'
  }
    
    return (
        <MainFilter>
          {openAccAssesmentDlg && <ModalDialog title={globals.acc_assessment.label} onClose={()=>setOpenAccAssesmentDlg(false)}>
            <div>
            {
              Object.keys(colors).map(key=>
                <AccordionCmp defaultExpanded={false} icon={null} summary={
                <div style={{display: 'flex', gap:'10px', justifyContent: 'space-between', marginBottom:'10px',width:'100%'}}>
                  <div style={{display: 'flex', gap:'10px', alignItems:'center'}}>
                    <CircleIcon sx={{color:colors[key]}}/>
                    <div>
                      <a href="#">{globals.acc_assessment.code_list[key]}</a>
                    </div>
                  </div>
                  <StyledSwitch checked={props.selectedFilter[key]} onChange={()=>props.onChange(key)} size="small" />
                </div>
                }>
                  {globals.acc_assessment.code_list_note[key]}
                </AccordionCmp>
              )
            }
            </div>
            </ModalDialog>}
          {globals.groups.map(g=>
          <div className={props.disabled ? 'disabled' : ''} key={g.key}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:'10px'}}>
              <div style={{display: 'flex', gap:'10px', alignItems:'center'}}>
                <FilterIcon key={g.key}>
                    <img className="modro" style={{height:'25px'}} src={props.selectedFilter[g.key] ? g.icons.modro : g.icons.sivo} />
                </FilterIcon>
                <div>
                  {globals.uit[g.key]}
                </div>
              </div>
              <StyledSwitch disabled={props.disabled || false} checked={props.selectedFilter[g.key]} onChange={()=>props.onChange(g.key)} size="small" />
            </div>
            {g.key == '_mobility' && 
              <div style={{marginTop:'-5px', marginLeft:'20px'}}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{marginTop:'-10px'}} onClick={onAccAssessmentClicked}>
                    <div style={{display: 'flex', justifyContent:'flex-end', alignItems:'center', gap: '10px'}}>
                      <div><a href="#">{globals.acc_assessment.label}</a></div>
                      <div style={{display: 'flex', gap:'5px'}}>
                        {
                          Object.keys(colors).map(key=>props.selectedFilter[key] && <CircleIcon sx={{ color: colors[key] }} />)
                        }
                      </div>
                    </div>
                  </ListItemButton>
                </List>
              </div>
            }
            </div>
          )}
        </MainFilter>
    );
  }