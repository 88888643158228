import L from 'leaflet';

import lcm from './leaflet-canvas-markers';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

import 'leaflet-geosearch/dist/geosearch.css'

import ModeEditIcon from '@mui/icons-material/ModeEdit';

import contactIcon from './assets/02_ikone/contact.svg';

import { JsonToTable } from "react-json-to-table";

import utils from './utils';

import { renderToString } from 'react-dom/server'

import globals from './globals';

import s from './settings';
import { IconButton } from '@mui/material';

lcm(L);

//import 'leaflet/dist/leaflet.css';  //default marker se ni kazal, če je bilo to vključeno tukaj
//https://stackoverflow.com/questions/59970774/dont-generate-map-click-events-when-marker-clicked-in-leaflet?rq=1

export default () => {
  let map = null;
  let layerControl = null;
  let geocoder = null;
  let suLayer = null;
  let noLabelsLayer = null;
  let osmLayer = null;
  let mmLayers = {};
  let _view = null;

  let mejeObcinLayer = null;

  const initial = {
    center: [48.596614694207965, 15.33330064066901],
    zoom: 7,
    minZoom: 7
  }

  function init(op = {}) {
    // create map
    map = L.map('map', {
      center: initial.center,
      zoom: initial.zoom,
      minZoom: initial.minZoom,
      maxZoom: initial.minZoom,
      zoomControl: false,
      renderer: L.canvas()
    });

    map.createPane('suSelection');
    map.getPane('suSelection').style.zIndex = 250;

    const osmUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    const osmAttribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    const baseMaps = {
      "CartoDB.PositronNoLabels": L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19
      }),
      "OpenStreetMap": L.tileLayer(osmUrl, {
        attribution: osmAttribution,
        maxZoom: 19
      }),
      "ESRI World Imagery": L.tileLayer.wms('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        maxZoom: 19
      })
    };

    Object.keys(baseMaps).map(key => {
      baseMaps[key].on('add', (e) => e.target.bringToBack());
    });

    layerControl = L.control.layers(baseMaps, [], { collapsed: false }).addTo(map);

    document.getElementsByClassName('leaflet-control-container')[0].appendChild(document.getElementsByClassName('leaflet-control-layers')[0]);

    noLabelsLayer = baseMaps['CartoDB.PositronNoLabels'].addTo(map);

    osmLayer = baseMaps['OpenStreetMap'];

    new L.TileLayer(osmUrl, { minZoom: 0, maxZoom: 13, attribution: osmAttribution });

    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider: provider,
      style: 'bar',
      notFoundMessage: 'Iskanje ni obrodilo sadov.',
      showPopup: true,
      autoClose: false,
      showMarker: true,
      keepResult: true,
      searchLabel: 'Iskanje po imenu kraja ...'
    });

    map.addControl(searchControl);

    document.getElementsByClassName('leaflet-geosearch-bar')[1].style.display = 'none';

    op.onMapClicked && map.on('click', op.onMapClicked);
    op.onPopupOpened && map.on('popupopen', op.onPopupOpened);

    map.on('zoomend', function (event) {

    });
  }

  function popupHTML(feature, attributeDefinitions, currentLanguage) {
    const popupFeatureProperties = {};
    console.log('globals.',globals.groups)
    let ikoneRanljivihSkupinHtmlImagesJoined = [];
    for (const g of globals.groups) {
      
      if (['hr','hu','cs','pl'].indexOf(feature.properties._language) > -1 && g.key !== '_mobility') {
        continue;
      }
      else if (feature.properties._language == 'de' && ['_mobility', '_visual'].indexOf(g.key) === -1) {
        continue;
      }

      if (feature.properties[g.key]) {
        ikoneRanljivihSkupinHtmlImagesJoined.push(`<img src="${g.icons.modro}">`);
      }
      else {
        ikoneRanljivihSkupinHtmlImagesJoined.push(`<img src="${g.icons.sivo}">`);
      }
    }

    ikoneRanljivihSkupinHtmlImagesJoined = ikoneRanljivihSkupinHtmlImagesJoined.join('');

    const attributeDefinitionsByName = {};
    for (const a of attributeDefinitions) {
      a._visible = true;
      attributeDefinitionsByName[a.cname] = a;
    }

    //umakne komentarje na slike iz tabele atributov
    for (const a of attributeDefinitions) {
      if (a.is_photo) {
        attributeDefinitions.map(ad => {
          if (ad.cname !== a.cname && ad.cname.startsWith(a.cname)) {
            ad._visible = false;
          }
        })    
      }
    }

    for (const a of attributeDefinitions) {
      if (a._visible !== false && !a.is_photo) {
        const key = a.cname;
        const keyEn = key + '_en';
        if (a.data_type==='string' && attributeDefinitionsByName[keyEn]) {
          if (feature.properties.language !== currentLanguage) {
            if (feature.properties[keyEn] && feature.properties[keyEn].trim()) {
              a._visible = false;
            }
          }
          else {
            if (feature.properties[key] && feature.properties[key].trim()) {
              attributeDefinitionsByName[keyEn]._visible = false;
            }
          }
        }
      }
    }

    let hasPhotos = false;

    for (const a of attributeDefinitions) {

      const key = a.cname;
      
      if (a._visible === false) continue;
      
      let value = feature.properties[key];

      if (!value) continue;

      if (a.is_photo) {
        hasPhotos = true;
      }

      const rawValue = value.trim();

      if (a.code_list && Object.keys(a.code_list).length > 0) {
        value = a.code_list[value];
      }
      else if (a.is_photo) {
        continue;
      }
      else if (a.data_type === "date") {  //convert date
        const a = value.split('-');
        value = `${a[2]}. ${a[1]}. ${a[0]}`;
      }

      if (a.code_list && value) {
        value = value[currentLanguage] || value['en'];
      }

      if (key === 'acc_assessment') {
        const color = globals.settings.acc_assessment_colors[rawValue];
        value = `<div style="display: flex;justify-content: space-between; gap: 5px;">
                    <a class="acc-assessment-link" href="#">${value}</a>
                    <span style='font-size:20px;color:${color}'>&#9679;</span>
                </div>`;
      }

      popupFeatureProperties[a.label || key] = value;
    }

    const isEditor = sessionStorage.getItem('editor');
    const maxHeight = 'calc(100vh - 180px)';

    return `<div class="popup-div" style="max-height: ${maxHeight}">
        <div>
          <div style="display:flex; justify-content: space-between">
            <div style="${isEditor ? '' : 'display:none'}"><a class="edit-link" href="#">${renderToString(<ModeEditIcon sx={{color:'red'}}></ModeEditIcon>)}<strong>Edit</strong></a></div>
            <div style="margin-top:-10px;margin-left:-10px" class="popup-contact">
              ${renderToString(<IconButton><img style={{width: '27px'}} src={contactIcon}></img></IconButton>)}
            </div>
            <div style="display: flex; gap: 5px">
              <div style="margin-right:5px" class="ranljive-skupine-popup">
                ${ikoneRanljivihSkupinHtmlImagesJoined}
              </div>
            </div>
          </div>
          <div class="popup-feature-props" style="margin-right:5px; max-height: 170px; overflow-y:auto">
              ${utils.json2htmlTable(popupFeatureProperties)}
          </div>
        </div>
        ${hasPhotos ? 
          `<div class="photo-container" style="width:310px; height:100%; max-height:200px; margin-left:5px">
            <div class="image-gallery-container"></div>
          </div>` : ''}
      </div>`;
  }

  function onEachFeature(feature, leafletLayer, layerDefinition, currentLanguage) {
    leafletLayer.bindPopup('', { maxWidth: 640 });
    const popupObj = leafletLayer.getPopup();
    popupObj._mm_feature = feature;
    popupObj._mm_table_name = layerDefinition.table_name;
    leafletLayer.feature = feature;
  }

  /* https://gist.github.com/geog4046instructor/80ee78db60862ede74eacba220809b64
   * Create a custom icon to use with a GeoJSON layer instead of the default blue
   * marker. This snippet assumes the map object (map) and GeoJSON object
   * (myLayerData) have already been declared.
   */

  const addLayer = (geomData, attributeData, layerDefinition) => {

    const codeList = globals.codeList;

    const currentLanguage = localStorage.getItem('language');

    const attributes = layerDefinition.attributes

    const groupAttributeVisibilityKeys = globals.groups.map(g => g.key.replace('_', ''));

    const { icon, lineColor, lineWidth, dashArray, opacity } = layerDefinition.props;

    const iconImg = icon;

    let features = [];

    const geomDataByGid = {};

    geomData.map(g => {
      geomDataByGid[g.gid] = g.geom;
    })

    for (const featureProperties of attributeData) {

      const feature = { "type": "Feature", properties: {} };

      let validFeature = true; //some features were without geometry

      for (const p of featureProperties) {
        if (p[0] === 'gid') {
          const g = geomDataByGid[p[1]];

          if (!g) {
            validFeature = false;
            break;
          }

          feature.gid = p[1];

          feature.geometry = JSON.parse(g);
        }
        else {
          feature.properties[p[0]] = p[1];
        }
      }

      validFeature && features.push(feature);
    }

    let options = {
      filter: function (feature, layer) {
        return true;
      },
      onEachFeature: (feature, layer) => onEachFeature(feature, layer, layerDefinition, map, currentLanguage)
    };

    options.style = {};

    if (lineColor) {
      options.style.color = lineColor;
    }

    if (lineWidth) {
      options.style.weight = lineWidth;
    }

    if (dashArray) {
      options.style.dashArray = dashArray;
    }

    if (opacity) {
      options.style.opacity = opacity;
    }

    const layerGroup = L.layerGroup();

    features.map(feature => {
      const geom = feature.geometry;

      let layer = null;

      if (geom.type === 'MultiPoint' && geom.coordinates && geom.coordinates[0]) {
        const [lng, lat] = geom.coordinates[0];
        layer = L.canvasMarker({ lat, lng }, {
          img: {
            url: '/icn/' + iconImg,
            size: [21, 21],
            offset: { x: 0, y: 0 }
          }
        });

        onEachFeature(feature, layer, layerDefinition, map, currentLanguage);

      }
      else if (geom.type === 'MultiLineString') {
        layer = L.geoJSON([feature], options);
      }

      layer && layerGroup.addLayer(layer);

    });

    layerGroup.addTo(map);

    if (!mmLayers) mmLayers = {};

    mmLayers[layerDefinition.table_name] = layerGroup;

    return layerGroup;
  }

  const zoomToLayer = (layer) => {
    map.fitBounds(layer.getBounds());
  }

  const setInitialMapView = () => {
    map.setView(initial.center, initial.zoom);
  }

  const zoomPlus = () => {
    map.setZoom(map.getZoom() + 1);
  }

  const zoomMinus = () => {
    map.setZoom(map.getZoom() - 1);
  }

  const releaseView = () => {
    if (!_view) return;
    map.setView(_view.latlng, _view.zoom);
    _view = null;
  }

  function showSuLayer(show) {
    const _lay = suLayer;

    if (show) {
      _lay.addTo(map)
      noLabelsLayer.addTo(map);
      map.removeLayer(mejeObcinLayer);
    }
    else {
      map.removeLayer(_lay)
    }
  }

  function showOsmLayer(show) {

    if (show) {
      map.removeLayer(noLabelsLayer);
    }
    else {
      noLabelsLayer.addTo(map);
    }

    show ? osmLayer.addTo(map) : map.removeLayer(osmLayer)
  }

  //return Object.freeze({
  return {
    setInitialCenter: (center) => initial.center = center,
    popupHTML,
    init,
    layerControl: () => layerControl,
    geocoder: () => geocoder,
    map: () => map,
    addLayer,
    zoomToLayer,
    setInitialMapView,
    zoomPlus,
    zoomMinus,
    showOsmLayer: showOsmLayer,
    getMmLayers: () => mmLayers,
    lockView: (latlng, zoom) => {
      _view = {
        latlng: latlng,
        zoom: zoom
      }
    },
    releaseView: releaseView
  };
}