import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    '.Mui-expanded & > .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)'
    },
    '&.Mui-expanded': {
  
    }
  }));
  
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  export default function AccordionCmp (props) {

    return (<Accordion defaultExpanded={props.defaultExpanded !== undefined ? props.defaultExpanded : true}>
        <AccordionSummary
        expandIcon={props.icon !== undefined ? props.icon : <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        >
        {props.summary}
        </AccordionSummary>
        <AccordionDetails>
            {props.children}
        </AccordionDetails>
    </Accordion>);
  }