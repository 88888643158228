import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

import utils from '../utils';

import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import globals from '../globals';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const LayerListDialog = styled('div')({
    padding:'10px',
    fontSize:'13px',
    color: 'rgb(21,77,127)'
  });

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: 'rgb(21,77,127)',
      '&:hover': {
        backgroundColor: alpha('rgb(21,77,127)', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'rgb(21,77,127)',
    },
  }));
  //.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked 

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    fontFamily: 'Raleway, sans-serif',
    fontSize: '11pt'
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{display:'flex'}}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

export default (props) => {

  //count all objects

  if (!(globals.attributesDefinitionByLayer && globals.spatialNamesCounts)) return null;

      return (
        <LayerListDialog>
          <div className={props.disabled ? 'disabled' : ''}>
          {Object.keys(props.items).map((key, index) => {
            const item = props.items[key];
            return (<div key={key + '_' + index} style={{display:'flex', alignItems:'center', marginTop:'5px'}}>
              <div style={{display:'flex', alignItems: 'center'}}>
                <IconButton style={{marginRight:'10px', padding:'2px'}}><img src={'/icn/'+item.props.icon}/></IconButton>
                <div style={{maxWidth:'80%'}}>{item.label}</div>
              </div>
              <div style={{marginLeft:'auto', display: 'flex',alignItems:'center'}}>
                <div style={{display:'flex',alignItems:'center', justifyContent: 'flex-end', width:'42px'}}>
                  ({props.featureCount[key] || 0})
                  <StyledSwitch checked={props.checked[item.table_name]===true} onChange={(event) => props.handleLayerSwitchChange(event, item.table_name)} size="small" {...label} />                
                </div>
              </div>
            </div>);
          })}
          </div>
        </LayerListDialog>
    );
  }