import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import globals from '../globals';

import { styled } from '@mui/material/styles';

const data = [
  { "value": "sl", "label": "Slovenščina" },   // Slovenian
  { "value": "en", "label": "English" },        // English
  { "value": "pl", "label": "Polski" },         // Polish
  { "value": "de", "label": "Deutsch" },        // German
  { "value": "hr", "label": "Hrvatski" },       // Croatian
  { "value": "cs", "label": "Čeština" },        // Czech
  { "value": "hu", "label": "Magyar" }          // Hungarian
];

const TextFieldStyled = styled(TextField)({
  '& .MuiInputBase-sizeSmall': {
    height:'33px'
  },
  '& .MuiInputBase-input': {
    height: '16px',
    fontSize: '15px'
  },
  '& .MuiInputLabel-root': {
    lineHeight: '1em'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: `16px`,
    },
  }
});

export default function LanguageSelector(props) {
  return (
        <TextFieldStyled
          id="outlined-select-currency"
          select
          style={{width:'100%'}}
          onChange={props.onChange}
          size="small"
          label={globals.uit._language}
          defaultValue={props.selectedLanguage}
        >
          {data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextFieldStyled>
  );
}