import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  fontFamily: 'Raleway, sans-serif',
  fontSize: '11pt'
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  
  const handleClose = () => {
    props.onClose();
  };

  return (
      <BootstrapDialog
        fullWidth={props.fullWidth || false}
        maxWidth={props.maxWidth || 'sm'}
        disableEscapeKeyDown={props.disableEscapeKeyDown || false}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle style={{fontFamily:'Raleway, sans-serif', color: 'rgb(21,77,127)'}} id="customized-dialog-title" onClose={handleClose}>
            {props.title || <div style={{height:"0.85em"}}></div>}
        </BootstrapDialogTitle>
         
        <DialogContent dividers sx={{color: 'rgb(21,77,127)'}}>
            {props.children}
        </DialogContent>
        {props.onSave &&
          <DialogActions>
            <Button onClick={props.onSave} type="submit">{(props.button && props.button.label) || 'Save'}</Button>
          </DialogActions>
        }
      </BootstrapDialog>
  );
}
