import * as React from 'react';
import Drawer from './MiniVariantDrawer';
import ModalDialog from './components/ModalDialog';
import globals from './globals';

function App() {
  const [disclaimer, setDisclaimer] = React.useState(false);

  const onDisclaimerClose = () => {
    localStorage.setItem('hide_disclaimer', 1);
    setDisclaimer(false);
  }

  return (
    <div className="App">
      {disclaimer && <ModalDialog title={globals.uit._disclaimer} onClose={onDisclaimerClose} onSave={onDisclaimerClose} button={{label:'OK'}}>
        <div>
          {globals && globals.uit && globals.uit.disclaimer}
        </div>
      </ModalDialog>}
      <Drawer setDisclaimer={setDisclaimer}></Drawer>
    </div>
  );
}

export default App;
