const globals = {
    spatialNamesCounts: [],
    layers: [],
    leafletLayers: {}
};

const drawerWidth = 360;
const closedDrawerWidth = 0;
globals.closedDrawerWidth = closedDrawerWidth;
globals.drawerWidth = drawerWidth;

const currentLanguage = localStorage.getItem('language');
if (!currentLanguage) {
    localStorage.setItem('language', 'en');
}

globals.settings = {
    acc_assessment_colors: {
            acc_unknown:'gray',
            acc_not: 'red',
            acc_partially:'orange',
            acc_fully:'green'
    }
}

globals.uit = {};

export default globals;