/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import globals from '../globals';

import { styled } from '@mui/material/styles';

const AutocompleteStyled = styled(Autocomplete)({
  '& .MuiInputBase-input': {
    height: '16px',
    fontSize: '15px'
  },
  '& .MuiInputLabel-root': {
    lineHeight: '1em'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: `16px`,
    },
  }
  });

export default function ComboBox(props) {

  const onItemSelected = (event, value) => {
    props.onItemSelected(value && value.id);
  }

  const selectedItem = props.items.find(item => item.id == props.selectedItemId) || null;

  return (
    <AutocompleteStyled
      style={props.style}
      id="combo-box-demo"
      size="small"
      value={selectedItem}
      options={props.items}
      getOptionLabel={(option) => option.label}
      onChange={onItemSelected}
      className="omeji-zapise"
      renderInput={(params) => <TextField {...params} label={globals.uit._pilot_area_name} size="small" variant="outlined" />}
    />
  );
}