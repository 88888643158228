import * as React from 'react';
import ModalDialog from './ModalDialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';

import axios from 'axios';

import isEmail from 'validator/lib/isEmail';

import globals from '../globals';

import s from '../settings';

const baseUrl = s[s.config].apiUrl.replace('/api','');

let timer = null;

export default function ContactDialog(props) {
  const [imageData, setImageData] = React.useState(null);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState({});


  const fetchCaptchaImage = async () => {
    try {
      const response = await axios.get('math-captcha');
      const base64String = response.data; // Assuming the response.data contains the base64 string

      // Set the base64 string as the image data
      setImageData(base64String);

      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      
      timer = setTimeout(() => fetchCaptchaImage(),55000);
    } catch (err) {
      console.error('Error fetching image:', err);
    }
  };

  const onSend = async () => {
    
    const _errors = {};
    ['note', 'email', 'captcha','gdpr'].map(key=>{
      if (!data[key]) {
        _errors[key] = key==='gdpr' ? globals.uit._gdpr_required : globals.uit._contact_required;
      }
    });

    if (!_errors.email) {
      if (!isEmail(data['email'])) {
        _errors.email = globals.uit._email_error || 'Napačen mail';
      }
    }

    if (Object.keys(_errors).length > 0) {
      setError(Object.assign({}, _errors));
    }
    else {
      try {
        
        if (props.data && props.data.featureProperties) {
          data.note = `LAYER: ${props.data.tname},\nid: ${props.data.gid},\nname: ${props.data.featureProperties.name || ''}\n--------------------------------------------\n${data.note}`;
        }

        const res = await axios.post('notify',{
          key: imageData.key,
          captcha: data.captcha,
          note: data.note,
          email: data.email
        });
        props.onClose({
          severity:'success',
          msg: globals.uit._notify_success
        });
      }
      catch(err) {
        if (err.response && err.response.data && err.response.data.error) {
          const sendError = err.response.data.error;
          if (sendError === 'captcha') {
            fetchCaptchaImage();
            setError(Object.assign({},{'captcha': globals.uit._captcha_error}));
          }
          else if (sendError === 'email') {
            alert(globals.uit._email_send_error)
          }
        }
      }
    }
  }

  return (
      <ModalDialog 
        fullWidth={true}
        disableEscapeKeyDown={true}
        maxWidth={'sm'}
        onClose={props.onClose} title={globals.uit._contact_note_title}>
        <div >
            <div>
                <TextField
                  onChange={e => setData(Object.assign(data, {note: e.target.value}))}
                  id="outlined-multiline-static"
                  sx={{width:'100%'}} 
                  label={globals.uit._contact_note}
                  multiline
                  helperText={error.note}
                  error={error.note ? true : false}
                  rows={5}
                />
            </div>
            <br></br>
            <div>
                <TextField
                    onChange={e => setData(Object.assign(data, {email: e.target.value}))}
                    size="small"
                    sx={{width:'100%'}}
                    label="e-mail"
                    type={"email"}
                    helperText={error.email}
                    error={error.email ? true : false}
                />
            </div>
            <br></br>
            <FormControl error={error.gdpr ? true : false} required>
              <FormGroup>
                <FormControlLabel required control={<Checkbox onChange={e => {
                  const value = e.target.checked;
                  
                  if (value===true) {
                    fetchCaptchaImage();
                  }
                  else {
                    clearTimeout(timer);
                    timer = null;
                    setImageData(null);
                  }

                  setData(Object.assign(data, {gdpr: value}))
                }}/>} label={
                  <div style={{fontSize:'11px'}}>{globals.uit.gdpr}</div>
                  } />
                  <FormHelperText>{error.gdpr}</FormHelperText>
              </FormGroup>
            </FormControl>
            {imageData && (
                <div>
                  <br></br>
                  <div style={{marginBottom:'10px'}}>{globals.uit._captcha}</div>
                    <div style={{display: 'flex'}}>
                      <div>
                        <img
                            src={imageData.img}
                        />
                        <FormHelperText><a onClick={()=>fetchCaptchaImage()} href="#">{globals.uit._refresh_captcha}</a></FormHelperText>
                      </div>
                      <div style={{flex: 1}}>
                        <TextField 
                          error={error.captcha ? true : false} 
                          helperText={error.captcha} 
                          style={{marginTop:'-2px', width:'100%'}} 
                          onChange={e => setData(Object.assign(data, {captcha: e.target.value}))} size="small">
                        </TextField>
                      </div>
                    </div>
                </div>
            )}
            <br></br>
            <div style={{display:'flex'}}>
            <Button onClick={onSend} style={{marginLeft:'auto', marginRight:0}} variant="outlined" endIcon={<SendIcon />}>
                {globals.uit._contact_send}
            </Button>
            </div>
        </div>
      </ModalDialog>
  );
}
