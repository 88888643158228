import * as React from 'react';
import ModalDialog from './ModalDialog';

import globals from '../globals';

export default function CustomizedDialogs(props) {
  console.log('globlas',globals)
  return (
      <ModalDialog onClose={props.onClose} title={"O projektu"}>
        <div>
          {globals.uit.info}
        </div>
      </ModalDialog>
  );
}
