var utils={};
utils.hstore2object = function(hstore) {
    if (!hstore) return hstore;
    hstore = hstore.substring(1, hstore.length-1);
    let r = hstore.split('", "');
    return r.reduce((acc,cval) => {
        let a = cval.split('"=>"');
        if (a.length===2) {
            acc[a[0]] = a[1];
        }
        return acc;
    }, {});
}

utils.json2htmlTable = (jsonObject) => {
    const rows = [];
    Object.keys(jsonObject).map(key => {
        const value = jsonObject[key];
        rows.push(`<tr><td><strong>${key}</strong></td><td>${value}</td></tr>`);
    });
    return '<div class="json-to-table" data-reactroot=""><table><tbody>' + rows.join('') + '</tbody></table></div>';
}

utils.getQueryParameter = (variable, decode = true) => {
    var query = window.location.search.substring(decode===true ? 3 : 1).trim();
    if (!query) return null;
    
    try {
        query = (decode && atob(query)) || query;
    }
    catch(e) {
        return null;
    }

    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

utils.ikoneRanljivihSkupin = (groups, checked, table_name) => {
    return groups.map(g => 
        <img 
          key={g.key}
          style={{height:'16px'}} 
          src={g.icons[checked[g.key][table_name] === true ? 'modro' : 'sivo']} 
        />);
}

export default utils;
