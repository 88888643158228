import * as React from 'react';
import L, { geoJSON, popup } from 'leaflet';
import ReactDOM from 'react-dom';
import {useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import ModalDialog from './components/ModalDialog';

import LayersIcon from '@mui/icons-material/Layers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import AttributeEditor from './components/AttributeEditor';

import ContactDialog from './components/ContactDialog';
import AlertSnackBar from './components/AlertSnackBar';

import CircleIcon from '@mui/icons-material/Circle';

import Multim2AppBar from './components/AppBar';

import List from '@mui/material/List';

import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AutoCompleteCombo from './components/AutoCompleteCombo';

import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import downloadModroSvg from './assets/02_ikone/Download_modro-01.svg';
import downloadBeloSvg from './assets/02_ikone/Download-01.svg';

import logotipGIS from './assets/01_logotipi/logotip_GIS_barvni-01.svg';
import logotipMZI from './assets/01_logotipi/logotip_MZI_barvni.svg';
import logotipMP from './assets/01_logotipi/logotip_MP.svg';

import TextField from '@mui/material/TextField';

import zoomPlus from './assets/02_ikone/Plus-01.svg';
import zoomMinus from './assets/02_ikone/Minus-01.svg';
import zoomPlusHover from './assets/02_ikone/Ikone_novo/Plus_modro-01.svg';
import zoomMinusHover from './assets/02_ikone/Ikone_novo/Minus_modro.svg';
import layersSvg from './assets/02_ikone/Layers2-01.svg';
import layersSvgHover from './assets/02_ikone/Layers2_negativ-01.svg';

import lupaSvg from './assets/02_ikone/Search-01-01.svg';
import lupaSvgHover from './assets/02_ikone/Search_belo-01.svg';


import map from './map';

import copy from 'copy-to-clipboard';

import axios from 'axios'; 
import s from './settings';

import LayerListDialog from './components/LayerListDialog';

import globals from './globals';
import utils from './utils';

import AboutDialog from './components/AboutDialog';

import SimpleSnackbar from './components/SnackBar';

import ImageCarousel from './components/ImageCarousel';
import MainFilter from './components/MainFilter';

axios.defaults.baseURL = s[s.config].apiUrl

const drawerWidth = globals.drawerWidth;
const closedDrawerWidth = globals.closedDrawerWidth;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth
});

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  '.Mui-expanded & > .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(0deg)'
  },
  '&.Mui-expanded': {

  }
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Footer = styled('div', {shouldForwardProp: prop => prop !== 'open'})(({ theme, open }) => ({
  height: '40px', 
  position: 'fixed', 
  bottom:0,
  right:0,
  backgroundColor:'rgb(241,242,242)',
  width: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)`,
  display: 'flex',
  justifyContent: 'right',
  img:{
    height:'36px'
  }
}));

export const SearchField0 = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const SearchField = styled(TextField)({
  fieldset: {
    borderRadius: '50px'
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

const MapDiv = styled('div')({
  width: `calc(100% - ${closedDrawerWidth}px)`,
  height: `calc(100vh - 70px)`,
  position: 'fixed',
  left: closedDrawerWidth,
  top: '70px',
  background: 'grey'
});

const MapIconButton = styled(IconButton)(
  (props) => ({
  height: '32px',
  width: '32px',
  img: {
    height: '32px'
  },
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  ':hover .img-base': {
    display: 'none'
  },
  ':hover .img-hover': {
    display: 'inherit'
  },
  '.img-base': {
    display: props._selected ? 'none' : 'inherit'
  },
  '.img-hover': {
    display: props._selected ? 'inherit' : 'none'
  }
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, color }) => ({
    width: drawerWidth,
    flexShrink: 0,
    //whiteSpace: 'nowrap',
    fontFamily: 'Raleway, sans-serif',
    '.MuiDrawer-paper': {
      backgroundColor: color || 'rgb(21,77,127)',
      color: (color && 'rgb(21,77,127)') || 'white'
    },
    fontWeight: 500,
    fontSize: '14pt',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const selectedMixin = (theme) => (
  {
    backgroundColor:'white',
    color: 'rgb(21,77,127)'
  }
);

const CustomListItem = styled(MuiListItem)(
  (props) => 
  {
  return {
    padding:0, 
    ...(props._selected && selectedMixin()),
    //borderTop:'solid 1px', 
    //borderColor:'#E0E0E0',
    ':hover':{
      ...selectedMixin()
    },
    ':hover .belo': {
      display: 'none'
    },
    ':hover .modro': {
      display: 'inherit'
    },
    '.modro': {
      display: props._selected ? 'inherit' : 'none'
    }
  }
}
);

let _map = null;

export default function MiniDrawer(props) {

  //let selectedPilotAreaId = null;

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const [checked, setChecked] = React.useState({});

  const [selectedMenuItem, setSelectedMenuItem] = React.useState({});

  const [selectedFilter, setSelectedFilter] = React.useState({
    'acc_fully': true,
    'acc_partially': true,
    'acc_not': true,
    'acc_unknown': true,
    '_mobility':true,
    '_visual': true,
    '_hearing':true
  });

  const [selectedPilotAreaId, setSelectedPilotArea] = React.useState();

  const [layersButtonSelected, setLayersButtonSelected] = React.useState(0);

  const [searchButtonSelected, setSearchButtonSelected] = React.useState(0);

  const [openAbout, setOpenAbout] = React.useState(false);

  const [openContactDialog, setOpenContactDialog] = React.useState(false);

  const [alertMessage, setAlertMessage] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [deepLink, setDeepLink] = React.useState('');

  const [onCopy, setOnCopy] = React.useState(false);

  const [error, setError] = React.useState('');

  const [featureCount, setFeatureCount] = React.useState({});

  const [attributeEditorOpened, setAttributeEditorOpened] = React.useState(false);

  const [attributeValueNoteOpened, setAttributeValueNoteOpened] = React.useState(false);

  const [galleryDialogOpened, setGalleryDialogOpened] = React.useState(false);


  const items2 = [
  {
    text: '',
    key: '_download',
    icons: {
      belo: downloadBeloSvg,
      modro: downloadModroSvg,
    },
    onClick: () => {
      axios.get('export-geopackage/'+selectedPilotAreaId, { responseType: 'blob' })
      .then(response => {
        // Create a URL for the blob response
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `spaces4all_pa${selectedPilotAreaId}.zip`);
        link.setAttribute('target', '_blank'); // For some browsers
        link.setAttribute('rel', 'noopener noreferrer'); // For security

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        // Handle error
        console.error('Error downloading GeoPackage:', error);
      });
    }
  }
  ];

  const onAttributeValuesSaved = async (featureProperties, tname, gid) => {
    
    try {
      const res = await axios.post('save-attributes',{featureProperties, tname, gid});

      const leafletMap = _map.map();
      _map._currentlyOpenedPopup._mm_feature.properties = Object.assign(_map._currentlyOpenedPopup._mm_feature.properties, featureProperties)
      leafletMap.closePopup();
      leafletMap.openPopup(_map._currentlyOpenedPopup);
    }
    catch(err) {

    }
  }

  const galleryItems = (slike, imageWrapperSize="small") => { //imageWrapperSize=small/large
    //const style={maxHeight: maxHeight, height:height, maxWidth: maxWidth, width:width}
    const items = slike.map((slika,inx) => 
            
            <div key={`wrap${inx}`} className={`image_wrapper ${imageWrapperSize}_image_wrapper`}>
              <img alt={slika.desc} key={`img${inx}`} src={s.fotoRoot + slika.src} />
              <div className="image_overlay">
                <h3>{slika.desc}</h3> 
              </div>
            </div>
            
            );
    return items;
  }
  
  useEffect(() => {
    _map = map();
    _map.init({
      onMapClicked: e => {
        
        document.getElementsByClassName('leaflet-control-layers')[0].style.display = 'none';
        setLayersButtonSelected(0);
      },
      onPopupOpened: e => {
        _map._currentlyOpenedPopup = e.popup;
        const tname = e.popup._mm_table_name;
        const feature = e.popup._mm_feature;
        const featureProperties = feature.properties;

        const attDefs = globals.attributesDefinitionByLayer[tname].attributes;

        const currentLanguage = localStorage.getItem('language');

        e.popup.setContent(_map.popupHTML(feature, attDefs, currentLanguage));

        const contact = e.popup.getElement().getElementsByClassName('popup-contact'); 

        const isEditor = sessionStorage.getItem('editor');
        if (isEditor) {
          const link = e.popup.getElement().getElementsByClassName('edit-link');
          link[0].addEventListener('click', function() {
              setAttributeEditorOpened({attDefs, featureProperties, tname, gid: feature.gid});
          });

          contact[0].style.display='none';
        }
        else {
          contact[0].addEventListener('click', function() {
            setOpenContactDialog({
              featureProperties,
              tname,
              gid:feature.gid
            }) ;
        });
        }

        for(const a of attDefs) {
          if (a.cname === 'acc_assessment' && featureProperties[a.cname]) {
            const rawValue = featureProperties[a.cname];
            const link = e.popup.getElement().getElementsByClassName('acc-assessment-link');
            link[0].addEventListener('click', function() {
              setAttributeValueNoteOpened({
                label: a.label,
                value: link[0].innerHTML, 
                note: globals.acc_assessment.code_list_note[rawValue]
              });
            })
            break;
          }
        }

        const photoContainer = e.popup.getElement().getElementsByClassName('photo-container')[0];

        if (photoContainer) { //show photos
          
          const containers = photoContainer.getElementsByClassName('image-gallery-container');
          const container = containers[0];
          const fotoAttributes = [];
          
          attDefs.map(a => {
            if (a.is_photo) {
              fotoAttributes.push(a);
            }
          });

          const slike = [];

          fotoAttributes.map(a => {
            const featureProperties = e.popup._mm_feature.properties;
            const foto = featureProperties[a.cname];
            if (foto) {
              const slabel = a.label + '_note';
              const noteAttributes = attDefs.filter(ad=>ad.label && ad.label.startsWith(slabel));

              const labelEn = featureProperties[a.cname + '_note_en'];

              let label = featureProperties[a.cname + '_note'] || labelEn;
              
              if (featureProperties.language !== currentLanguage && labelEn) {
                label = labelEn;
              }

              label = label && label.trim();

              foto.split('|').map(s => {
                const src = s.trim().toLowerCase();
                src && slike.push({
                  src: src,
                  desc: label || ''
                });
              });
            }
          });

          container.addEventListener('click', function() {
            setGalleryDialogOpened(galleryItems(slike, 'large'));
          });

          container.style.height='207px';
          const items = galleryItems(slike);
          ReactDOM.render(<ImageCarousel navButtonsAlwaysInvisible={items.length === 1 ? true : false} height={180} items={items}></ImageCarousel>, containers[0]);
        }
      }
    }); //_map.init

    const control = document.getElementsByClassName('leaflet-control-layers')[0];
    control.onmouseleave = function() {
      control.style.display = 'none';
      setLayersButtonSelected(0);
    }

    const instanceParameters = {};

    const p = {};

    if (s.instance==='pravosodje') {
      p.params = {};
      p.params.instance = s.instance;
      const objectId = utils.getQueryParameter('id', false);
      if (objectId) {
        p.params.object_id = objectId;
      }
    }

    axios.all([axios.get('/spatial/count-by-layer'), axios.get( '/attributes-definition'), axios.get( '/groups'), axios.get('/spatial/pilot-areas'),
    axios.get('/geom-all'), axios.get('/attribute-values-all'), axios.get('/ui-translations')
  ]).then(
      axios.spread(async (counts, attributesDefinition, groups, pilotAreas, geomAll, attributesAll, translations) => {

      globals.geomAll = geomAll;

      globals. attributesAll = attributesAll;

      globals.spatialNamesCounts = counts.data;

      const countsAll = {};

      Object.keys(globals.spatialNamesCounts).map(tname=>{
        countsAll[tname] = globals.spatialNamesCounts[tname].reduce((accumulator, currentValue) => {
            return accumulator + currentValue.c;
          }, 0);
      });

      globals.countsAll = countsAll;

      setFeatureCount(countsAll);

      globals.pilotAreas = pilotAreas.data;

      if (_map) {
        const leafletMap = _map.map();
        leafletMap.createPane('pilot_areas')
        const myRenderer = L.canvas({pane: 'pilot_areas'});
        const pilotAreasGeometry = {};
        const pacs = [];
        pilotAreas.data.map(pa => {
          const bbox = JSON.parse(pa.bbox).coordinates[0];
          pilotAreasGeometry[pa.pilot_area] = pa;
          //dolžina diagonala bbox-a v px
          const t1 = { lat:bbox[0][1], lng:bbox[0][0] };
          const t2 = { lat:bbox[2][1], lng:bbox[2][0] };

          pa.bounds = L.latLngBounds(t1, t2);
          
          const c = { lat: (t1.lat + t2.lat) / 2.0, lng: (t1.lng + t2.lng) / 2.0 };
          pacs.push(c);
          /*
          const c1 = leafletMap.project(t1, leafletMap.getZoom());
          const c2 = leafletMap.project(t2, leafletMap.getZoom());
          const diagonala = Math.sqrt(Math.pow(c2.x-c1.x,2) + Math.pow(c2.y-c1.y,2));
          const radius = Math.ceil(diagonala/2);
          */
         const radius = 80;
  
          const layer = L.canvasMarker(c, {
            renderer: myRenderer,
            radius: radius,
            img: {
              url: '/icn/Ikone_novo/pa' + pa.pilot_area + '.svg',
              size: [radius*2, radius*2], //v SVG datoteki je določen viewBox="0 0 50 50"
              offset: { x: 0, y: 0 }
            }
          });
          leafletMap.addLayer(layer);
          pa.layer = layer;

          layer.on('click', function() {
            onPilotAreaSelected(pa.pilot_area);
          });

        });
        globals.pilotAreasGeometry = pilotAreasGeometry;
        const viewCenter3 = pacs.reduce((p,c) => ({lat: p.lat + c.lat, lng: p.lng + c.lng}));
        const viewCenter = {lat:viewCenter3.lat/pacs.length,lng:viewCenter3.lng/pacs.length};
        leafletMap.setView(viewCenter, leafletMap.getZoom());
        _map.setInitialCenter(viewCenter);
      }

      const codeList = {};

      globals.groups = groups.data.filter(g => g.key!=='_starostniki');

      const attributesDefinitionByLayer = {};

      globals.pilotAreaItems = [];

      const currentLanguage = localStorage.getItem('language');

      globals.uit = {};

      translations.data.map(row => {
        const translations = JSON.parse(row.translations);
        globals.uit[row.key] = translations[currentLanguage] || translations['en'];
      });

      const leafletMap = _map.map();

      leafletMap.attributionControl.addAttribution(`| <a id="disclaimer" href="#", class="your_class"><b>CE-Spaces4All</b> ${globals.uit._disclaimer_link_short}</a> `);

      const link = document.getElementById("disclaimer");

      link.addEventListener('click', function() {
        props.setDisclaimer(true);
      });

      attributesDefinition.data.map(ld => {
        if (s.instance==='pravosodje' && ['fizicne_ovire_zacasne','tocke'].indexOf(ld.table_name)!==-1) return;
        
        if (!ld.label) ld.label='{}';

        const currentLanguage = localStorage.getItem('language');

        ld.label = JSON.parse(ld.label);

        ld.label = ld.label[currentLanguage] || ld.table_name;

        ld.props = (ld.props && JSON.parse(ld.props)) || {};
        ld.props.icon = 'Ikone_novo/' + ld.table_name + '.svg';
        ld.attributes = ld.attributes.sort(function (a, b) {
          return a.weight - b.weight;
        });

        ld.attributes.map(a => {
          ['code_list','code_list_note'].map(clkey=>{
            if (a[clkey]) {
              a[clkey] = JSON.parse(a[clkey]);
            }
          });
          
          if (!a.label) a.label='{}';

          a.label = JSON.parse(a.label);
          a.label = a.label[currentLanguage] || a.cname;

        });

        const attributesByKey = {};
        ld.attributes.map((a)=>attributesByKey[a.cname] = a);
        
        ld.attributesByKey = attributesByKey;

        if (ld.table_name === '_common') {
          const paProps = ld.attributesByKey['pilot_area'];
          if (paProps) {
            Object.keys(paProps.code_list).map(key => {
              globals.pilotAreaItems.push({
                id: key,
                label: paProps.code_list[key][currentLanguage] || paProps.code_list[key]['en']  
              })
            })
          }
        }
        else {
          attributesDefinitionByLayer[ld.table_name] = ld;
        }
      });

      globals.attributesDefinitionByLayer = attributesDefinitionByLayer;

      const acc_assesment = attributesDefinitionByLayer.public_transport_stations.attributes.find(a => a.cname == 'acc_assessment');
      if (acc_assesment) {
        globals.acc_assessment = JSON.parse(JSON.stringify(acc_assesment));
      }

      ['code_list','code_list_note'].map(clkey=>{
        Object.keys(globals.acc_assessment[clkey]).map(key => {
          const values = globals.acc_assessment[clkey][key];
          globals.acc_assessment[clkey][key] = values[currentLanguage] || values['en'];
        });
      });
      
      globals.codeList = codeList;

      globals.groups.map(g => {
        g.icons = {
          belo: '/icn/assets/' + g.key+'_belo.svg',
          modro: '/icn/assets/' + g.key+'_modro.svg',
          sivo: '/icn/assets/' + g.key+'_sivo.svg'
        }
      });

      const initialChecked = {};

      setChecked(initialChecked);

      const objectId = utils.getQueryParameter('id', false);

      const qlids = utils.getQueryParameter('lids');
      
      const lids = qlids ? JSON.parse(qlids) : [];

      const loadedLayers = {};

      let numberOfLayersToLoad = 0;
      let numberOfLoadedLayers = 0;

      for (const layerKey of lids) {
        numberOfLayersToLoad++;
        loadLayer(layerKey, () => {
          numberOfLoadedLayers++;
          if (!loadedLayers) loadedLayers = {};
          loadedLayers[layerKey] = true;

          initialChecked[layerKey] = true;
          
          if (numberOfLayersToLoad === numberOfLoadedLayers) {
            setChecked(initialChecked);
            setDeepLinkMapView(loadedLayers);
          }
        },objectId);
      }
      
      if (!localStorage.getItem('hide_disclaimer')) {
        props.setDisclaimer(true);
      }

    }))
    .catch(()=>{})
    .then(()=>{
      setLoading(false);
    });
    

  }, []);

  function setDeepLinkMapView(loadedLayers={}) {
    
    const z = utils.getQueryParameter('z');
    const lat = utils.getQueryParameter('lat');
    const lng = utils.getQueryParameter('lng');

    const gid = utils.getQueryParameter('gid');
    if (gid) {
      onPilotAreaSelected(gid,()=>{
        refreshCurrentlyLoadedMmMapLayers(gid);
        const leafletMap = _map.map();
        leafletMap.setView({lat,lng}, z);
      });
    }
  }

  function addAllLayers() {
    const initialChecked = {};

    Object.keys(globals.attributesDefinitionByLayer).map(key => {
      initialChecked[key] = true;
      addLayerFromResponse(key, globals.geomAll.data[key], globals.attributesAll.data[key], ()=>{showFeatures(selectedPilotAreaId, key, _map.map())});
    });

    setChecked(initialChecked);
  }

  const zoomToInstanceObjects = (lids) => {

      const filteredLayersBounds = [];

      const polyline = L.polyline([]);

      if (globals.leafletLayers) {
        Object.keys(globals.leafletLayers).map(layerKey=> {
          if (layerKey === 'linije') {
            const layers = globals.leafletLayers.linije.getLayers();
            if (layers.length > 0) {
              layers.map(layer=>filteredLayersBounds.push(layer.getBounds()));
            }
          }
          else {
            const layer = globals.leafletLayers[layerKey];
            if (layer) {
              const pnts = layer.getLayers() || [];
              pnts.map(pnt => polyline.addLatLng(pnt.getLatLng()));
            }
          }
        });
      }

      if (polyline.getLatLngs().length > 0) {
        filteredLayersBounds.push(polyline.getBounds());
      }
        
      let minLat = 90;
      let minLng = 180;
      let maxLat = -90;
      let maxLng = -180;

      filteredLayersBounds.map(bounds => {
        const ne = bounds.getNorthEast();
        
        if (ne.lat > maxLat ) maxLat = ne.lat;
        if (ne.lng > maxLng) maxLng = ne.lng;

        const sw = bounds.getSouthWest();
        
        if (sw.lat < minLat ) minLat = sw.lat;
        if (sw.lng < minLng) minLng = sw.lng;

      });

      _map.map().flyToBounds([[minLat, minLng], [maxLat, maxLng]]);  //fitBounds

      const objektiTeren = globals.leafletLayers._mobility && globals.leafletLayers._mobility['objekti_teren'];
      if (objektiTeren) {
        const layers = objektiTeren.getLayers() || [];
        if (layers.length>0) {
          const target = layers[layers.length-1];
          target && target.openPopup();
        }
      }
  }

  const onMenuItemClicked = (item) => {
  
    if (item.key === 'PREVZEM_PODATKOV') {
      item.onClick();
    }

    setSelectedMenuItem(item);
  }

  const handleLayerSwitchChange = (event, layerKey) => {
    const _checked = event.target.checked;

    const checkedObject = Object.assign({}, checked);
    checkedObject[layerKey] = _checked;
    setChecked(checkedObject);
    
    if (_checked) {
      loadLayer(layerKey, () => {
        showFeatures(selectedPilotAreaId, layerKey, _map.map());
      });
    }
    else {
      const layer = globals.leafletLayers[layerKey];
      layer && _map.map().removeLayer(layer);
    }
  };

  const refreshCurrentlyLoadedMmMapLayers = (gid = null) => {
    const mmLayers = _map.getMmLayers();
    const map = _map.map();

    const filteredFeatureCount = {};

    mmLayers && Object.keys(mmLayers).map(layerKey => {
      if (map.hasLayer(mmLayers[layerKey])) {
        filteredFeatureCount[layerKey] = showFeatures(gid, layerKey, map);
      }
    });
    
    setFeatureCount(gid ? filteredFeatureCount : Object.assign({}, globals.countsAll));
  }

  const showFeatures = (gid, layerKey, map) => {

    const isEditor = sessionStorage.getItem('editor');

    const filteredGroups = [];
    const filteredAccAssessments = [];

    globals.groups.map(g => {
      if (selectedFilter[g.key]) {
        filteredGroups.push(g.key);
      }
    });

    Object.keys(globals.acc_assessment.code_list).map(key=>selectedFilter[key] && filteredAccAssessments.push(key));

    const isFiltered = layer => {
      if (layer.getLayers) {
        const layers =  layer.getLayers();
        if (layers.length > 0) {
          return isFiltered(layers[0]);
        }
        else {
          return false;
        }
      }

      const props = layer.feature && layer.feature.properties;
      
      if (props && props.pilot_area == gid) {
        let checkAssessmentFilter = false;
        
        if (isEditor) {
          checkAssessmentFilter = true;
        }
        else {
          for(const groupKey of filteredGroups) {
            if (props[groupKey]) {
              if (groupKey === '_mobility' && props.acc_assessment !== undefined) {
                checkAssessmentFilter = true;
                continue;
              }
              else {
                return true;
              }
            }
          }
        }

        //acc_assessment filter
        if (checkAssessmentFilter) {
          const value = props.acc_assessment;
          if (!value) return true; //empty acc_assesment features are shown

          for(const acc of filteredAccAssessments) {
            if (props.acc_assessment === acc) {
              return true;
            }
          }
          return false;
        }

      }

      return false;
    }

    let cnt = 0;

    globals.leafletLayers[layerKey].getLayers().map(layer => {
      let addLayer = false;

      if (gid) {
        addLayer = isFiltered(layer);
      }
      else {
        addLayer = false; //gid===null
      }

      if (addLayer) {
        cnt++;
        const ll = layer.addTo(map);
        if (layerKey !== 'linije') {
          ll.bringToFront();
        }
        else {
          ll.bringToBack();
        }
      }
      else {
        layer.remove();
      }

    });

    return cnt;
  }

  const onPilotAreaSelected = (paId, callback) => {
    const gid = paId;

    if (!callback) {
      callback = () => {
        refreshCurrentlyLoadedMmMapLayers(gid);
      }
    }

    const handlePilotAreaSelection = (gid = null, callback=null) => {

      const leafletMap = _map.map();

      const pane = leafletMap.getPane('pilot_areas');

      const controls = document.getElementsByClassName('map-controls');

      if (gid) {
        _map.showOsmLayer(true);
        
        Object.keys(globals.pilotAreasGeometry).map(paId => {
          globals.pilotAreasGeometry[paId].layer.remove();
        });

        pane.style.display = 'none';
        controls[0].style.display = '';

        _map.map().setMaxZoom(19);

        leafletMap.flyToBounds(globals.pilotAreasGeometry[gid].bounds);  //fitBounds
      }
      else {
        _map.setInitialMapView();
        _map.showOsmLayer(false);

        pane.style.display = "";
        controls[0].style.display = "none";

        _map.map().setMaxZoom(_map.map().getMinZoom());

        Object.keys(globals.pilotAreasGeometry).map(paId => {
          globals.pilotAreasGeometry[paId].layer.addTo(leafletMap);
        });
        
        setFeatureCount(Object.assign({}, globals.countsAll));

        setChecked({});
      }
  
      setSelectedPilotArea(gid);
  
      callback && callback(gid);
  
    }

    if (gid) {
      window.localStorage.setItem('multim2.gid', gid);
      addAllLayers();
      handlePilotAreaSelection(gid, callback);
    }
    else {
      window.localStorage.removeItem('multim2.gid');
      handlePilotAreaSelection(null, callback);
    }

    const link = document.getElementById("disclaimer");

    link.addEventListener('click', function() {
      props.setDisclaimer(true);
    });
  }

  function addLayerFromResponse(layerKey, geomResponseData, attributeResponseData, onLayerLoaded) {
    const layerDefinition = globals.attributesDefinitionByLayer[layerKey];
    if (!globals.leafletLayers) globals.leafletLayers = {};

    if (!globals.leafletLayers[layerKey]) {
      const layer = globals.leafletLayers[layerKey] = _map.addLayer( geomResponseData, attributeResponseData, layerDefinition);
    }

    onLayerLoaded && onLayerLoaded(layerKey);
  }

  const loadLayer = (layerKey, onLayerLoaded=null,objectId='') => {

    if (globals.leafletLayers && globals.leafletLayers[layerKey]) {
        _map.map().addLayer(globals.leafletLayers[layerKey]);
        showFeatures(selectedPilotAreaId, layerKey, _map.map());
        return;
    }
    setLoading(true);

    const p = {};

    if (s.instance === 'pravosodje') {
      p.params = {};
      p.params.instance = s.instance;
      p.params.object_id = objectId;
    }

    axios.all([ axios.get( `/geom/${layerKey}`, p), axios.get( `/attribute-values/${layerKey}`, p )]).then(
      axios.spread( (geomResponse, attributeResponse ) => {
        addLayerFromResponse(layerKey, geomResponse.data[layerKey], attributeResponse.data[layerKey], onLayerLoaded)
      })
    )
    .catch(error => {
      console.log(error);
      setError(error.message);
    })
    .then(() => {
      setLoading(false);
    } //always executed
    );
  }

  const onOpenAboutIconClicked = () => {
    setOpenAbout(true);
  }

  const onContactIconClicked = () => {
    setOpenContactDialog(true)
  }

  const onFilterChange = (key) => {
    
    if (selectedFilter[key]) {
      selectedFilter[key] = false;
    }
    else {
      selectedFilter[key] = true;
    }

    refreshCurrentlyLoadedMmMapLayers(selectedPilotAreaId);

    setSelectedFilter({...selectedFilter});
  }

  const onShareIconClicked = () => {
    const map = _map.map();
    const {lat, lng} = map.getCenter();
    const z = map.getZoom();

    const gid = selectedPilotAreaId;

    const p = [`lat=${lat}`,`lng=${lng}`,`z=${z}`];

    const lids = [];

    Object.keys(checked).map(layerKey => {
      if (checked[layerKey]) {
        lids.push(layerKey);
      }
    });

    p.push('lids='+JSON.stringify(lids));

    gid && p.push(`gid=${gid}`);

    const _loc = window.location;

    const dlink = `${_loc.origin}${_loc.pathname}?p=${btoa(p.join('&'))}`;

    setDeepLink(dlink);

    const res = copy(dlink);
    if (res === true) {
      setOnCopy(true);
      setTimeout(()=>setOnCopy(false),5000)
    }
  }

  const items = [
    {
      Icon: LayersIcon,
      key: 'layers'
    },
    {
      Icon: FilterAltIcon,
      key: 'filters'
    }
  ];

  const layers = globals.attributesDefinitionByLayer || [];
  
  return (
    <div>
      {galleryDialogOpened &&
        <ModalDialog fullWidth maxWidth='xl' onClose={() => setGalleryDialogOpened(false)}>
            <ImageCarousel navButtonsAlwaysInvisible={galleryDialogOpened.length === 1 ? true : false} items={galleryDialogOpened}></ImageCarousel>
        </ModalDialog>
      }
      {attributeValueNoteOpened && 
      <ModalDialog title={attributeValueNoteOpened.label} onClose={() => setAttributeValueNoteOpened(false)}>
        <strong>{attributeValueNoteOpened.value}</strong> {attributeValueNoteOpened.note}
      </ModalDialog>}
      {attributeEditorOpened && <AttributeEditor onAttributeValuesSaved={onAttributeValuesSaved} attr={attributeEditorOpened} onClose={() => setAttributeEditorOpened(false)}></AttributeEditor>}
      <Multim2AppBar setMenuOpen={setOpen} isMenuOpen={open} {...loading} instance={s.instance} onContactIconClicked={onContactIconClicked} onShareIconClicked={onShareIconClicked} onOpenAboutIconClicked = {onOpenAboutIconClicked}
        AutoCompleteGrouped = {<AutoCompleteCombo selectedItemId={selectedPilotAreaId || null} onItemSelected={onPilotAreaSelected} items={globals.pilotAreaItems || []}></AutoCompleteCombo>}
      >
      </Multim2AppBar>
      {openAbout && <AboutDialog onClose={()=>setOpenAbout(false)}></AboutDialog>}
      <AlertSnackBar onClose={()=>setAlertMessage(false)} open={alertMessage ? true : false} severity={alertMessage && alertMessage.severity} msg={alertMessage && alertMessage.msg}></AlertSnackBar>
      {openContactDialog && <ContactDialog data = {openContactDialog} onClose={(alert)=>{
        alert && setAlertMessage({
          msg: alert.msg,
          severity: alert.severity
        });

        setOpenContactDialog(false);
      }
      }></ContactDialog>}

      <Drawer variant="permanent" open={open} color="white">
        <div style={{display: 'flex', flexDirection:'column', justifyContent:'space-between', height:'100%'}}>
          <div>
            <img id="logo" onClick={()=> window.location = window.location.origin} src={"/CE-Spaces4All_Logo_Standard_medium.png"} className="App-logo" alt="logo" />

            <div style={{padding:'10px', paddingTop:0, marginTop:'-10px'}}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<LayersIcon></LayersIcon>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{globals.uit._layers}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LayerListDialog 
                    disabled={selectedPilotAreaId ? false : true}
                    selectedPilotAreaId={selectedPilotAreaId}
                    onClose={()=>{}} 
                    checked = {checked} 
                    handleLayerSwitchChange = {handleLayerSwitchChange} items = {layers} featureCount={featureCount} >
                  </LayerListDialog>

                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<FilterAltIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >

                  <Typography>{globals.uit._selected_content}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MainFilter disabled={selectedPilotAreaId ? false : true} selectedFilter={selectedFilter} onChange={onFilterChange}>
                  </MainFilter>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <List>
            {items2.map((item, index) => {
              return (
                <div key={'lower' + index} style={{display: 'flex'}}>
                <ListItemButton disabled={selectedPilotAreaId ? false : true } key={item.key} onClick={() => item.onClick()}>
                  <ListItemIcon style={{width:'65px', display: 'flex', justifyContent: 'center'}}>
                      <img className="modro" style={{height:'30px'}} src={item.icons.modro} />
                  </ListItemIcon>
                  <div style={{fontSize: 'smaller'}}>{globals.uit && globals.uit[item.key]}</div>
                </ListItemButton>
              </div>
              );
              })}
          </List>
        </div>
      </Drawer>

      <MapDiv id="map"></MapDiv>
      <div className="map-controls" style={{display: 'none'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {<MapIconButton _selected={searchButtonSelected} style={{marginBottom: "10px"}} onClick ={
                () => {
                  const control = document.getElementsByClassName('leaflet-geosearch-bar')[1];

                  if (searchButtonSelected == 1) {
                    control.style.display = 'none';
                    setSearchButtonSelected(0);
                  }
                  else {
                    control.style.display = 'inherit';
                    setSearchButtonSelected(1);
                  }
                }
              }>  
                <img className="img-base" src={lupaSvg} />
                <img className="img-hover" src={lupaSvgHover} />
              </MapIconButton>}

              {<MapIconButton _selected={layersButtonSelected} style={{marginBottom: "10px"}} onClick={
                ()=>{
                  const control = document.getElementsByClassName('leaflet-control-layers')[0];
                  if (layersButtonSelected == 1) {
                    control.style.display = 'none';
                    setLayersButtonSelected(0);
                  }
                  else {
                    control.style.display = 'inherit';
                    setLayersButtonSelected(1);
                  }
                }
              }>  
                <img className="img-base" src={layersSvg} />
                <img className="img-hover" src={layersSvgHover} />
              </MapIconButton>
              }
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <MapIconButton style={{marginBottom: "10px"}} onClick={()=>_map.zoomPlus()}>  
                <img className="img-base" src={zoomPlus} />
                <img className="img-hover" src={zoomPlusHover} />
              </MapIconButton>
              <MapIconButton  onClick={()=>_map.zoomMinus()}>  
                <img className="img-base" src={zoomMinus} />
                <img className="img-hover" src={zoomMinusHover} />
              </MapIconButton>
            </div>
      </div>
      {error && <SimpleSnackbar message={error}></SimpleSnackbar>}
      {onCopy && <SimpleSnackbar anchorOrigin = {{ vertical: 'top', horizontal: 'right' }} message={globals.uit._link_was_copied}></SimpleSnackbar>}
    </div>
  );
}
